import axios from "axios";

import {
  AIRTABLE_BASE_ID,
  AIRTABLE_KEY,
  BUSHA_ENV,
} from "app/constants/variables";
import Announcement from "app/models/announcement";
import { GenericAirtableListResponse } from "../types";

export const AIRTABLE_PRODUCT = "alice";

export const listAnouncements = (country: string = "NGN") => {
  const requestUrl = `https://api.airtable.com/v0/${AIRTABLE_BASE_ID}/announcements?filterByFormula=AND(FIND('%24%7B${country}%7D'%2C+%7Bcountries%7D)%2C+SEARCH(%22%24%7B${AIRTABLE_PRODUCT}%7D%22%2C+PRODUCT)%2C+DATETIME_DIFF(expiry%2C+NOW()%2C+'days')+%3E+-1%2C+%7BENVIRONMENT%7D+%3D+%22%24%7B${BUSHA_ENV}%7D%22)`;

  return axios.get<GenericAirtableListResponse<Announcement>>(requestUrl, {
    headers: {
      Authorization: `Bearer ${AIRTABLE_KEY}`,
    },
  });
};

const AnncounementService = {
  listAnouncements,
};

export default AnncounementService;
