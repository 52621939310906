import NavigationItem from "app/models/navigationItem";

import { ReactComponent as Explore } from "app/assets/icons/explore-outline.svg";
import { ReactComponent as Portfolio } from "app/assets/icons/wallet-outline.svg";
import { ReactComponent as TrendUp } from "app/assets/icons/trend-up.svg";
import { ReactComponent as Earn } from "app/assets/icons/percent-outline.svg";
import { ReactComponent as Spend } from "app/assets/icons/spend-outline.svg";
import { ReactComponent as Profile } from "app/assets/icons/profile-outline.svg";
import { ReactComponent as ConnectIcon } from "app/assets/icons/peer_chain.svg";

const appLinks: NavigationItem[] = [
  {
    label: "Explore",
    url: "/",
    icon: Explore,
  },
  {
    label: "Portfolio",
    url: "/spot",
    icon: Portfolio,
    exact: false,
  },
  {
    label: "Trade",
    url: "/trade",
    icon: TrendUp,
    exact: false,
  },
  {
    label: "Spend",
    url: "/airtime",
    icon: Spend,
    children: [
      { label: "Airtime", url: "/airtime" },
      { label: "Data", url: "/data" },
      { label: "Sports Bet", url: "/sports-bet" },
      { label: "Vouchers", url: "/vouchers" },
    ],
  },
  {
    label: "Earn",
    url: "/yield",
    icon: Earn,
    exact: false,
  },
];

export const profileLink: NavigationItem = {
  label: "Profile",
  url: "/profile",
  icon: Profile,
  exact: false,
};

export const merchantLinks = [
  { url: "/connect", label: "Connect", icon: ConnectIcon },
];

export default appLinks;
